import {
  FC,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useWindowSize } from '../../../hooks/use-window.hook';
import { appStoreContext } from '../../../stores/context.store';
import { Button } from '../../components/button';
import './workspace.styles.scss';

export const Workspace: FC = observer(() => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const { user_id } = useParams();
  const [iFrameKey] = useState<number>(0);

  const AppStore = useContext(appStoreContext);
  const { token, loading } = AppStore.pretender;

  const { height } = useWindowSize();
  const frameHeight = height - 32;

  const sendToken = useCallback((): void => {
    const target = iframeRef.current;
    const key = window.btoa(AppStore.config.headerKey);

    if (target?.contentWindow && token) {
      target.contentWindow.postMessage(
        {
          token,
          key,
        },
        '*',
      );
    }
  }, [AppStore.config.headerKey, token]);

  // useEffect(() => {
  //   if (iframeRef.current) {
  //     iframeRef.current.src += '';
  //     setTimeout((): void => setIFrameKey((key) => key + 1), 1000);
  //   }
  // }, [token]);

  // useEffect(() => {
  //   const timer = setInterval(sendToken, 1000);
  //   return () => clearInterval(timer);
  // }, [sendToken]);

  useEffect(() => {
    AppStore.pretender.pretend(user_id);

    return () => {
      AppStore.pretender.reset();
    };
  }, []);

  return (
    <div className="workspace">
      <div className="workspace__controls">
        <Button
          className="workspace__controls__button"
          size="sm"
          variant="white"
          disabled={loading && !!iframeRef.current}
          onClick={sendToken}
        >
          Выполнить вход
        </Button>
      </div>
      <iframe
        className="workspace__iframe"
        title="unione"
        key={iFrameKey}
        src={AppStore.config.iframeURI}
        width="100%"
        height={frameHeight}
        ref={iframeRef}
        onLoad={sendToken}
      />
    </div>
  );
});
