import cookie from 'cookiejs';
import { makeAutoObservable, runInAction } from 'mobx';
import { IGetUserTokenRoute } from '../models/routers/user.models';
import { IPretenderStore } from '../models/stores/pretender.models';
import { IRootStore } from '../models/stores/root.models';
import { SupportBackendRoutes } from '../routes/backend.routes';

export class PretenderStore implements IPretenderStore {

  public readonly rootStore: IRootStore;

  public token?: string = undefined;
  public loading: boolean = false;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;

    makeAutoObservable(this, {
      rootStore: false,
    });
  }


  public clear(): void {
    this.token = undefined;
  }


  public reset(): void {
    this.clear();
    cookie.clear();
  }


  public async pretend(userId?: string): Promise<void> {
    try {
      runInAction(() => {
        this.clear();
        this.loading = true;
      });

      if (typeof userId === 'string' && userId.length > 0) {
        const response = await this.rootStore.requests.json<IGetUserTokenRoute>({
          method: 'GET',
          baseURL: this.rootStore.config.supportAPI,
          route: SupportBackendRoutes.getUserToken,
          token: this.rootStore.user.token,
          params: {
            userId,
          },
        });

        runInAction(() => {
          this.token = response.token;
          this.loading = false;
        });

        return;
      }

      const localToken = this.rootStore.config.localToken;
      if (typeof localToken === 'string' && localToken.length > 0) {
        runInAction(() => {
          this.token = localToken;
          this.loading = false;
        });

        return;
      }
    }
    catch (error) {
      runInAction(() => {
        this.rootStore.errors.setError(error);
        this.loading = false;
      });
    }
  }

}
